<template>
    <v-snackbar v-model="snackbarVisible" color="red">
        {{ snackbarText }}
    </v-snackbar>
    <v-snackbar v-model="snakbarVisible" color="primary"> {{ snakbarText }}
      <template v-slot:actions>
      <v-btn color="white" @click="snakbarVisible = false">Закрыть
      </v-btn>
      </template>
    </v-snackbar>

    <div>
        <v-card>
        <v-window v-model="tab">
            <v-window-item>
                <v-row>
                    <v-text-field
                        style="margin: 15px"
                        v-model="search"
                        label="Поиск"
                        prepend-inner-icon="mdi-magnify"
                        single-line
                        variant="outlined"
                        hide-details
                    />
                    <v-spacer></v-spacer>
                    <v-btn v-if="isAuth" color="primary" style="margin-top: 30px; margin-bottom: 30px; margin-right: 10px;" @click="responsibleReportDialog = !responsibleReportDialog">
                        Отчет по преподавателю
                    </v-btn>
                    <v-btn v-if="isAuth" color="primary" style="margin-top: 30px; margin-bottom: 30px; margin-right: 30px;" @click="newResponsibleDialog = !newResponsibleDialog">
                        Добавить запись
                    </v-btn>
                </v-row>
                <v-data-table :search="search"
                          class="elevation-1" :headers="headers" :items="responsibles">
              <template v-slot:[`item.delete`]="{ item }" v-if="isAuth">
                <v-icon
                    size="small"
                    @click="editResponsibleDialog = true, this.editingItem = item">
                  mdi-pencil
                </v-icon>
                <v-icon
                    size="small"
                    @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
            </v-window-item>
        </v-window>
      </v-card>

      <v-dialog height="500" width="400" v-model="newResponsibleDialog">
      <v-card style="padding: 20px" elevation="11">
        <v-card-title>Добавить преподавателя</v-card-title>

        <v-form ref="form"  fast-fail>
          <v-text-field
              variant="outlined"
              label="ФИО и должность"
              :rules="[rules.required]"
              v-model="newResponsible.name"
          />    
          <v-row style="margin-top:10px" justify="center" >
            <v-btn type="submit" color="green" @click.prevent="submitForm">Сохранить</v-btn>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="300px">
      <v-card>
        <v-card-title style="text-align: center" class="text-h8">Удалить запись?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="tonal" @click="deleteItemConfirm()">Удалить</v-btn>
          <v-btn color="primary" variant="tonal" @click="closeDelete()">Отмена</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog height="auto" width="400px" v-model="editResponsibleDialog">
      <v-card style="padding: 20px;" elevation="11">
        <v-card-title style="text-align: center">Редактирование</v-card-title>
        <v-text-field variant="outlined" label="ФИО и должность" v-model="editingItem.name" />
        <v-row justify="center">
          <v-btn style="margin-left:3px; margin-top: 10px" color="green" @click="saveEditResponsible()">Сохранить</v-btn>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="responsibleReportDialog" max-width="600px">
      <v-card>
        <template v-slot="title">
          <div style="text-align: center; font-weight: 450; font-size: 18px; margin-top: 3px;">Выберите преподавателя</div>
          <v-autocomplete
                      :items="responsibles"
                      item-title="name"
                      :item-value = "item => item.id"
                      variant="outlined"
                      label="Ответственный"
                      :rules="[rules.required]"
                      v-model="reportResponsible.id"
                      style="padding-bottom: 0px; margin-top: 10px">
                  </v-autocomplete>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" variant="tonal" @click="createReport()">Сформировать</v-btn>
            <v-btn color="primary" variant="tonal" @click="closerResponsibleReport()">Отмена</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </template> 
      </v-card>
    </v-dialog>

    </div>

</template>

<script>
import API from '../auth.js'
import axios from "axios";

export default ({
    watch() {},
    created() {
        this.getAllResponsibles();
    },
    components:{},
    data() {
        return{
            rules: {
                required: value => !!value || 'Это поле обязательно для заполнения',
            },

            api: process.env.VUE_APP_ENV_API,


            tab: null,

            isAuth: true,

            search: '',
            snackbarText: '',
            snackbarVisible: false,
            snakbarText : '',
            snakbarVisible: false,
            newResponsibleDialog: false,
            dialogDelete: false,
            editResponsibleDialog: false,
            responsibleReportDialog: false,

            reportResponsible: {},
            newResponsible: {},
            deleteResponsible: {},
            editingItem: {},
            responsibles: [],
            headers: [
                {title: 'Ответственный', key: 'name'},
                {title: '', key: 'delete', sortable: false, align:'right'}
            ]
        }
    },
    methods: {
        createReport() {
            console.log(this.reportResponsible);
        axios({
            url: `${this.api}api/documents/responsible/${this.reportResponsible.id}`,
            method: 'GET',
            responseType: 'blob', // Важно указать 'blob' для бинарных данных
        }).then(response => {
            // Создаем элемент ссылки для запуска скачивания
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'mesyachniy_plan.xlsx'); // Имя файла для скачивания
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Очистка
        }).catch(error => {
            console.error("Ошибка при загрузке файла:", error);
        });
        },
        closerResponsibleReport(){
            this.responsibleReportDialog = false;
        },
        getAllResponsibles(){
            axios.get(this.api + 'api/responsibles/').then(response => {
            let responsiblesArray = [];
            response.data.message.forEach(responsible => {
                responsiblesArray.push(responsible);
                });
                this.responsibles = responsiblesArray;
            })
        },
        deleteItemConfirm() {
            axios.delete(this.api + 'api/responsibles/' + this.deleteResponsible.id).then(response => {
                if(response.status === 200)
                {
                    this.getAllResponsibles();
                    this.snakbarVisible = true;
                    this.snakbarText = "Запись успешно удалена.";
                    this.dialogDelete = !this.dialogDelete;
                }
            })
        },
        deleteItem(item) {
            this.deleteResponsible = item;
            this.dialogDelete = true
        },
        closeDelete(){
            this.dialogDelete = false;
        },
        async submitForm(){
            const isValid = await this.$refs.form.validate();

            if (isValid.valid) {
                this.pushNewResponsible();
            } else {
                this.snackbarText = "Пожалуйста, заполните все обязательные поля";
                this.snackbarVisible = true;
            }
        },
        pushNewResponsible(){
            axios.post(this.api +'api/responsibles/', this.newResponsible).then(response =>{
                if(response.status === 201){
                    this.getAllResponsibles();
                    this.newResponsible = {};  
                    this.newResponsibleDialog = false;
                    this.snakbarVisible = true;
                    this.snakbarText = "Запись успешно добавлена.";
                }
            })
        },
        saveEditResponsible(){
            axios.put(this.api + 'api/responsibles/', this.editingItem).then(response => {
                if(response.status === 200)
                {
                    this.editResponsibleDialog = false;   
                    this.snakbarText = "Заявка изменена";
                    this.snakbarVisible = true;
                }
            })
        }
    },
})</script>