<template>
    <VCalendar
      v-model="currentDate"
      :events="formattedEvents"
      color="primary"
      type="month"
      text="Сегодня"
    >
    <template v-slot:event="{ event } ">
      <v-card       @click="openURL(event)" :style="{ marginTop: '10px', backgroundColor: event.isHighPriority ? '#f4e1d2' : '#E7E7FF' }">
        <div class="event-title">{{ event.title }}</div>
      </v-card>
    </template>
  </VCalendar>
    
  <v-dialog
      v-model="eventDialog"
      width="1024">
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
                cols="12"
                sm="6"
                md="6"
                style="margin-bottom: 0px"
            >
              <v-text-field
                  variant="outlined"
                  label="Ответственный"
                  v-model="event.responsible"
                  style="padding-bottom: 0px"
                  readonly>
              </v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="6"
                style="padding-bottom: 0px"
            >
              <v-text-field
                  variant="outlined"
                  label="Площадка"
                  v-model="event.place.name"
                  style="margin-bottom: 0px">
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-row style="margin: 0px; padding-top: 0px">
                <v-text-field
                    variant="outlined"
                    label="Мероприятие"
                    v-model="event.name"
                    readonly>
                </v-text-field>
              </v-row>
            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
              <v-text-field variant="outlined"
                            type="date"
                            label="Дата начала"
                            v-model="event.startDate"
                            readonly>
              </v-text-field>

            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
              <v-text-field
                  readonly
                  variant="outlined"
                  type="date"
                  label="Дата окончания"
                  v-model="event.endDate">
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-data-table
          :headers="docsHeaders"
          :items="eventDocs">
          <template v-slot:[`item.name`]="{ item }" v-model="event.docs">
            <a :href="getDocUrl(item.name)" target="_blank">
              {{ item.name }}
            </a>
          </template>
        </v-data-table> 
        <v-spacer></v-spacer>
        <v-btn
            color="blue-darken-1"
            variant="text"
            @click="eventDialog = false"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </template>
  
  
  <script>
  import { VCalendar } from 'vuetify/labs/VCalendar';
  import API from '../auth.js';
  
  export default {
    components: {
      VCalendar
    },
    data() {
      return {
        currentDate: new Date(),
        events: [],
        eventDialog: false,
        event: {},
        eventDocs: [],
        docsHeaders:[
          {title: '', key:'name', sortable: false, align: 'center'},
        ],
        availableColors: [
          'blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1',
          'red', 'pink', 'purple', 'light-blue', 'teal', 'lime', 'yellow', 'amber',
          'deep-orange', 'brown', 'blue-grey'
        ], // Расширенный набор цветов
      };
    },
    watch: {
        currentMonth() {
          this.currentDate = new Date()
          this.currentDate.setMonth(this.currentMonth-1)
        },
        event() {
        this.getEventDocs();
      }
    },
    props: {
      currentMonth: Number,
    },
    created() {
      this.getEvents();
    },
    computed: {
      formattedEvents() {
        const eventsArray = [];
        const usedColors = {}; // Для хранения использованных цветов по событиям
  
        this.events.forEach(event => {
          const start = new Date(event.startDate);
          const end = new Date(event.endDate);
  
          // Проверяем, если цвет для события уже был назначен, если нет — назначаем новый
          let color;
          if (usedColors[event.id]) {
            color = usedColors[event.id]; // Используем уже назначенный цвет
          } else {
            color = this.pickColor(); // Выбираем новый цвет
            usedColors[event.id] = color; // Сохраняем цвет для события
          }
  
          for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
            // Добавляем событие для каждого дня в диапазоне с одинаковым цветом
            eventsArray.push({
              title: event.name,
              start: new Date(d), // Указываем текущий день в цикле
              end: new Date(d),   // Событие длится один день
              color: color,
              responsible: event.responsible,
              place: event.place,
              startDate: event.startDate,
              endDate: event.endDate,
              name: event.name,
              docs: event.docs,
              isHighPriority: event.isHighPriority      // Один цвет для одного события
            });
          }
        });
  
        return eventsArray;
      },
    },
    methods: {
      openURL(e) {
        console.log(e)
      this.event = e;
      this.eventDialog = true;
    },
    getDocUrl(fileName) {
      const baseUrl = "https://evdl.anosov.ru/docs/";
      return `${baseUrl}${fileName}`;
    },
    getEventDocs() {
      this.eventDocs = Object.values(this.event.docs || {});
    },
      getEvents() {
        API.get('https://eventapi.anosov.ru/api/events/')
            .then(response => {
              this.events = response.data.message;
            })
            .catch(error => {
              console.error("Error fetching events: ", error);
            });
      },
      // Метод для выбора случайного цвета и удаления его из доступных
      pickColor() {
        if (this.availableColors.length === 0) {
          // Если все цвета использованы, восстановим массив доступных цветов
          this.resetColors();
        }
        const randomIndex = this.rnd(0, this.availableColors.length - 1);
        const selectedColor = this.availableColors[randomIndex];
        this.availableColors.splice(randomIndex, 1); // Удаляем выбранный цвет из доступных
        return selectedColor;
      },
      // Метод для восстановления массива цветов, если все цвета были использованы
      resetColors() {
        this.availableColors = [
          'blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1',
          'red', 'pink', 'purple', 'light-blue', 'teal', 'lime', 'yellow', 'amber',
          'deep-orange', 'brown', 'blue-grey'
        ];
      },
      // Генератор случайного числа для выбора цвета
      rnd(a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a;
      },
    }
  };
  </script>