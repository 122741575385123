<template>
    <VCalendar
        v-model="currentDate"
        :events="formattedEvents"
        type="month"
        :event-color="getEventColor"
        color="primary"
    >
      <template v-slot:event="{ event }">
        <v-card :style="getEventStyle(event)">
          <div class="event-title" :title="event.title">
            {{ event.title }}
          </div>
        </v-card>
      </template>
    </VCalendar>
  </template>
  
  <script>
  import { VCalendar } from 'vuetify/labs/VCalendar';
  import API from '../auth.js';
  
  export default {
    components: {
      VCalendar
    },
    data() {
      return {
        currentDate: new Date(),
        events: [],
        availableColors: [
          'blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1',
          'red', 'pink', 'purple', 'light-blue', 'teal', 'lime', 'yellow', 'amber',
          'deep-orange', 'brown', 'blue-grey'
        ],
      };
    },
    created() {
      this.getEvents();
    },
    computed: {
      formattedEvents() {
        const eventsArray = [];
        const usedColors = {};
  
        this.events.forEach(event => {
          const start = new Date(event.startDate);
          const end = new Date(event.endDate);
  
          let color;
          if (usedColors[event.id]) {
            color = usedColors[event.id];
          } else {
            color = this.pickColor();
            usedColors[event.id] = color;
          }
  
          for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
            eventsArray.push({
              title: event.name,
              start: new Date(d),
              end: new Date(d),
              color: color,
            });
          }
        });
  
        return eventsArray;
      },
    },
    methods: {
      getEvents() {
        API.get('https://eventapi.anosov.ru/api/events/')
            .then(response => {
              this.events = response.data.message;
            })
            .catch(error => {
              console.error("Error fetching events: ", error);
            });
      },
      pickColor() {
        if (this.availableColors.length === 0) {
          this.resetColors();
        }
        const randomIndex = this.rnd(0, this.availableColors.length - 1);
        const selectedColor = this.availableColors[randomIndex];
        this.availableColors.splice(randomIndex, 1);
        return selectedColor;
      },
      resetColors() {
        this.availableColors = [
          'blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1',
          'red', 'pink', 'purple', 'light-blue', 'teal', 'lime', 'yellow', 'amber',
          'deep-orange', 'brown', 'blue-grey'
        ];
      },
      rnd(a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a;
      },
      getEventColor(event) {
        return event.color;
      },
      getEventStyle(event) {
        return {
          backgroundColor: event.color,
          color: 'white',
          borderRadius: '4px',
          padding: '4px',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        };
      }
    }
  };
  </script>
  
  <style scoped>
  .event-card {
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    max-width: 100%;
  }
  
  .event-card:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .event-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  </style>